// stylelint-disable

// # Crimson Text

@font-face {
    font-family: "Engravers'GothicBTStdRegular";
    src: url('./fonts/EngraversGothicBTStdRegular/font.woff2') format('woff2'),
    url('./fonts/EngraversGothicBTStdRegular/font.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


//New Fonts
$font-main: "Engravers'GothicBTStdRegular", sans-serif;
$font-alt: "Requiem Display A", serif;
$font-alt1: "Requiem Fine A", serif;

$font-main-semiBold: "Engravers'GothicBTStdRegular", sans-serif;
$font-header: "Requiem Fine A", sans-serif;